<template>
  <v-container>
    <v-text-field
      v-model="email"
      :error-messages="emailErrors"
      :label="$t('common|email')"
      required
      outlined
      @input="$v.email.$touch()"
      @blur="$v.email.$touch()"
    />
    <v-text-field
      v-model="password"
      :error-messages="passwordErrors"
      label="Password"
      required
      outlined
      @input="$v.password.$touch()"
      @blur="$v.password.$touch()"
    />
  </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, email } from 'vuelidate/lib/validators'

export default {
  mixins: [validationMixin],
  validations: {
    email: { required, email },
    password: { required, minLength: minLength(7) }
  },
  data () {
    return {
      email: null,
      password: null
    }
  },
  computed: {
    emailErrors () {
      const errors = []
      if (!this.$v.email.$dirty) return errors
      !this.$v.email.email && errors.push(this.$t('warning|valid_email'))
      !this.$v.email.required && errors.push(this.$t('common|field_required'))
      return errors
    },
    passwordErrors () {
      const errors = []
      if (!this.$v.password.$dirty) return errors
      !this.$v.password.required && errors.push(this.$t('warning|password_required'))
      !this.$v.password.minLength && errors.push(this.$t('warning|password_type'))
      return errors
    }
  },
  methods: {
    reset () {
      this.email = null
      this.password = null
      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    validate () {
      this.$v.$touch()
      if (this.$v.$error) return false
      return true
    },
    getData () {
      return {
        email: this.email,
        password: this.password
      }
    }
  }
}
</script>
